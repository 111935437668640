import React from 'react';

import { StyledDateTime } from './InputGroup.styled';

export interface IDateTimeInput {
  formName: string;
  value: Date;
  onChange: (newVal: Date) => void;
}

const DateTimeInput = ({
  formName,
  onChange,
  value,
}: IDateTimeInput) => {
  return (
    <StyledDateTime
      name={formName}
      className="form-control"
      // @ts-ignore
      onChange={onChange}
      value={value as Date}
    />
  );
}

export default DateTimeInput;