import React from 'react';

export interface ISubmitButton {
  onClick: () => void;
  isLoading?: boolean;
  text: string;
  styles?: React.CSSProperties;
  size?: string;
  variant?: string;
}

const SubmitButton = ({
  onClick,
  isLoading = false,
  text,
  styles,
  size = 'large',
  variant
}: ISubmitButton) => {
  const buttonStyles = { marginTop: '15px' , ...styles,};
  const butonSizeClass = `btn-${size}`;
  const buttonClass = !variant ? 'btn-purple' : `btn-${variant}`;

  return (
    <button className={`btn ${butonSizeClass} ${buttonClass}`} style={buttonStyles} onClick={onClick}>{isLoading ? <div className="spinner-border" role="status"></div> : text}</button>
  );
}

export default SubmitButton;