import React from 'react';
import DualListBox from 'react-dual-listbox';


export interface IListBoxInput {
  formName: string;
  selectOptions: { value: string; label: string; }[];
  selected: string[];
  onChange: (newVal: string[]) => void;
}

const ListBoxInput = ({
  formName,
  selectOptions,
  onChange,
  selected,
}: IListBoxInput) => {
  return (
    <DualListBox
      id={formName}
      name={formName}
      options={selectOptions}
      selected={selected}
      onChange={onChange}
    />
  );
}

export default ListBoxInput;