/* eslint-disable @typescript-eslint/ban-ts-comment */
import { configureStore, DeepPartial } from '@reduxjs/toolkit';
import { setupListeners } from "@reduxjs/toolkit/query/react";

import rootReducer from './rootReducer';

import getPreloadedState from './getPreloadedState';
import { api } from '@features';
import { rtkQueryErrorLogger } from './middleware'

export type RootState = ReturnType<typeof rootReducer>;

export type PartialRootState = DeepPartial<RootState>;

const configureAppStore = (preloadedState: PartialRootState = {}) => {
  const store = configureStore({
    reducer: rootReducer,
    // @ts-ignore
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
      api.middleware,
      rtkQueryErrorLogger
    ),
    // @ts-ignore
    preloadedState,
  });

  return store;
};

export type StoreDispatch = ReturnType<typeof configureAppStore>['dispatch'];

export type StoreGetState = ReturnType<typeof configureAppStore>['getState'];

export { getPreloadedState };

export default configureAppStore;

setupListeners(configureAppStore().dispatch);
