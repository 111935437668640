import React from 'react';

import { StyledSelectInout } from './InputGroup.styled';

export interface ISelectInput {
  formName: string;
  options: { value: string; label: string; }[];
  value: string;
  onChange: (newVal: string) => void;
}

const SelectInput = ({
  formName,
  options,
  onChange,
  value,
}: ISelectInput) => {
  return (
    <StyledSelectInout
      id={formName}
      name={formName}
      className="form-control"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((option, idx) => <option key={idx} value={option.value}>{option.label}</option>)}
    </StyledSelectInout>
  );
}

export default SelectInput;