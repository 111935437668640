import React from 'react';

import { StyledCheckboxInput } from './InputGroup.styled';

export interface ICheckboxInput {
  formName: string;
  value: boolean;
  onChange: (newVal: string) => void;
}

const CheckboxInput = ({
  formName,
  onChange,
  value,
}: ICheckboxInput) => {
  return (
    <StyledCheckboxInput
      type="checkbox"
      id={formName}
      name={formName}
      className="form-control checkbox"
      checked={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}

export default CheckboxInput;