import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { selectIsLoggedIn, selectUserRole } from '@features';
import { useAppSelector } from '@store/hooks';

export interface ICardLayout {
  children?: JSX.Element | JSX.Element[];
  pageTitle: string;
  cardStyles?: React.CSSProperties;
  initialized?: boolean;
  showContent?: boolean;
}

const CardLayout = ({
  children,
  pageTitle,
  cardStyles,
  initialized = true,
  showContent = true,
}: ICardLayout) => {
  const userRole = useAppSelector(selectUserRole);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const [showContents, setShowContent] = useState(showContent);
  const navigate = useNavigate();

  useEffect(() => {
    if (initialized) {
      if (isLoggedIn && !showContent) {
        return navigate('/');
      }
      setShowContent(true);
    }
  }, [userRole, initialized, isLoggedIn]);

  return (
    <div>
      <div className="card-header" style={{ textAlign: 'center' }}>
        <h1 style={{fontWeight:"700"}}>{pageTitle}</h1>
      </div>
      <div className="container-lg mt-4" >
        <div className="row">
          <div className="col-12">
            {showContents && (
              <div className="card text-white bg-dark mb-3 ma-card-bg" style={cardStyles}>
                <div className="card-body" style={cardStyles}>
                  {children}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardLayout;