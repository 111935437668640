import {
  createEntityAdapter,
} from '@reduxjs/toolkit'

import { api } from '@features';
import { IItem } from './Item';

interface ListResponse<T> {
  page: number
  per_page: number
  total: number
  total_pages: number;
  success: boolean;
  items: T[]
}

export const itemsAdapter = createEntityAdapter();
const initialState = itemsAdapter.getInitialState();

export const itemApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getItems: builder.query({
      query: () => `/item?page=${1}&limit=${10000}`,
      transformResponse: (response: ListResponse<IItem>, meta, arg) => {
        return itemsAdapter.setAll(initialState, response.items)
      }
    }),
    getItemByIdOrDef: builder.query<IItem, string>({
      query: (idOrDef) => {
        return {
          url: `item/${idOrDef}`,
          method: 'GET',
        }
      },
      providesTags: ['Item'],
      transformResponse: (response: { item: IItem, success: true }, meta, arg) => {
        if (response.success) {
          return response.item;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }      
    }),
    getItemList: builder.query<ListResponse<IItem>, { page?: number; limit?: number; sort?: string; search?: string }>({
      query: ({ page = 1, limit = 25, sort, search }) => {
        let url = `/item?page=${page}&limit=${limit}`;
        if (search) url += `&search=${search}`;
        if (sort && sort.length) url += `&sort=${sort}`;
        return {
          url,
          method: 'GET',
        }
      },
      providesTags: ['Item'],
      transformResponse: (response: ListResponse<IItem>, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    updateItems: builder.mutation({
      query: (items: any) => {
        return {
          method: 'POST',
          url: `/item`,
          body: items,
        }
      },
      invalidatesTags: ['Item'],
      transformResponse: (response: { items: IItem[]; success: boolean; }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    })
  }),
});

export const { useGetItemByIdOrDefQuery, useGetItemListQuery, useUpdateItemsMutation } = itemApi;