import React, { useEffect, useRef, useState } from 'react';
import { LoadingBarRef } from 'react-top-loading-bar';

import { ErrorBoundary, PageLayout, Navbar } from '@components';
import {selectIsLoggedIn,  useGetCurrentUserQuery, useGetCurrentUserProfileQuery } from '@features'
import Routes from '@routes';

import { useAppSelector } from '@store/hooks';

const isProd = process.env.NODE_ENV === 'production';

function App() {
  const loadingBarRef = useRef<LoadingBarRef>(null);
  const [hasPassedMessage, setHasPassedMessage] = useState(false);
  const [ loaded, setLoaded] = useState(false);
  const [skip, setSkip] = useState(true);
  const { data: profile } = useGetCurrentUserProfileQuery(undefined, {
    skip
  });
  const { data: user  } = useGetCurrentUserQuery(undefined, {
    skip
  });
  const loggedIn = useAppSelector(selectIsLoggedIn);

  useEffect(() => {
    const url = window.location.href;
    if (url === 'https://midair2.gg/steam' || url === 'https://www.midair2.gg/steam') {
      window.location.href = 'https://store.steampowered.com/app/1231210/Midair_2/';
    }
  }, []);

  useEffect(() => {
    if (skip && loadingBarRef.current) {
      loadingBarRef.current.continuousStart();
    } else if (!skip && loadingBarRef.current) {
      loadingBarRef.current.complete();
    }
  }, [skip]);

  const initialLoading = async () => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      setSkip(false);
      //await dispatch(getCurrentUser());
    }
    setLoaded(true);
  }

  useEffect(() => {
    initialLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.location.search.includes('?token=')){
      if (!hasPassedMessage) {
        window.opener.postMessage({ token: window.location.search.substring(7, window.location.search.length), ok: true });
        setHasPassedMessage(true);
      }
      window.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("message", async (event) => {
      if (event.origin !== (isProd ? 'https://midair2.gg' : 'http://localhost:3000')) return;
      const { token, ok } = event.data;
      if (ok && token) {
        localStorage.setItem("jwtToken", token);
        setSkip(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((!user || !profile) && !loaded) return null;

  return (
    <ErrorBoundary>
      <Navbar user={user} loggedIn={loggedIn} notifications={user?.notifications || []}/>
      <PageLayout loadingRef={loadingBarRef}>
        <Routes loadingRef={loadingBarRef} />
      </PageLayout>
    </ErrorBoundary>
  );
}

export default App;
