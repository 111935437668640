import React from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar'
import { useNavigate } from 'react-router-dom';

import History from '@services/navigation';

interface IPageLayout {
  children?: JSX.Element;
  loadingRef: React.RefObject<LoadingBarRef>;
}

const PageLayout = ({
  children,
  loadingRef,
}: IPageLayout) => {
  History.navigate = useNavigate();

  const childrenWithRef = React.Children.map(children, el => {
    if (el) {
      return React.cloneElement(el, { loadingRef: loadingRef });
    }
    return null;
  });

  return (
    <div className="">
      <LoadingBar
        color='#d78d0d'
        height={7}
        ref={loadingRef}
        containerStyle={{ top: '58px', zIndex: 1 }}
        shadow={false}
        waitingTime={100000}
      />
      <section className="ma-dash">{childrenWithRef}</section>
    </div>
  );
};

PageLayout.displayName = 'PageLayout';

export default PageLayout;
