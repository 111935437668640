import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { IStream } from './Stream';

const streamAdapter = createEntityAdapter<IStream>({
  selectId: (stream) => stream.id,
});
export const initialStreamState = streamAdapter.getInitialState();

const streamSlice = createSlice({
  name: 'stream',
  initialState: initialStreamState,
  reducers: {},
});

export default streamSlice.reducer;