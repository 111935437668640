import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { IPublicMatch } from './PublicMatch';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const publicMatchAdapter = createEntityAdapter<IPublicMatch>({
  selectId: (payment) => payment._id,
});
export const initialPublicMatchState = publicMatchAdapter.getInitialState();

const publicMatchSlice = createSlice({
  name: 'publicMatch',
  initialState: initialPublicMatchState,
  reducers: {},
});

export default publicMatchSlice.reducer;

export const {
  selectAll: selectAllPublicMatches,
  selectById: selectPublicMatchById,
  selectIds: selectPublicMatchIds
} = publicMatchAdapter.getSelectors((state: RootState) => state.publicMatch);