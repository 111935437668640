import React from 'react';

import CheckboxInput from './CheckboxInput';
import DateTimeInput from './DateTimeInput';
import ImageInput from './ImageInput';
import ListBoxInput from './ListBoxInput';
import SelectInput from './SelectInput';
import TextAreaInput from './TextAreaInput';
import TextInput from './TextInput';
import {
  StyledInputContainer,
  StyledInputGroup,
  StyledInputLabel
} from './InputGroup.styled';

export interface ISelectOption {
  value: string;
  label: string;
}

export interface IInputGroup {
  /** Description of the input */
  description?: string;

  /** is this input disabled */
  disabled?: boolean;

  /** The text to display if an error has occured */
  errorText?: string;

  /** The text to display as label */
  labelText: string;

  /** The name of the form */
  formName: string;

  /** The max length an input can be */
  maxLength?: number;

  selectOptions?: ISelectOption[];

  selectedListBoxOptions?: string[];

  /** Should we show the error message */
  showError?: boolean;

  /** The type of input */
  type: 'checkbox' | 'datetime' | 'listbox' | 'text' | 'textarea' | 'image' | 'number' | 'select';

  value?: boolean | Date | string | number | Array<string> | undefined;

  onImageChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (newVal: string | number | Date | boolean) => void;
  onListBoxChange?: (selcted: string[]) => void;
}

const InputGroup = ({
  description,
  disabled = false,
  errorText,
  maxLength,
  formName,
  labelText,
  selectOptions,
  selectedListBoxOptions,
  showError = false,
  type,
  value,
  onImageChange,
  onChange,
  onListBoxChange,
}: IInputGroup) => {
  const containerClassName = type === 'datetime' ? 'col-9' : 'col-9'
  return (
    <StyledInputGroup>
      <StyledInputLabel htmlFor="name">{labelText}</StyledInputLabel>
      <StyledInputContainer className={containerClassName}>
        {type === 'checkbox' && onChange && (
          <CheckboxInput
            formName={formName}
            value={value as boolean}
            onChange={onChange}
          />
        )}
        {type === 'datetime' && onChange && (
          <DateTimeInput
            formName={formName}
            value={value as Date}
            onChange={onChange}
          />
        )}
        {type === 'listbox' && onListBoxChange && (
          <ListBoxInput
            formName={formName}
            onChange={onListBoxChange}
            selectOptions={selectOptions || []}
            selected={selectedListBoxOptions || []}
          />
        )}
        {type === 'image' && onImageChange && (
          <ImageInput
            formName={formName}
            onChange={onImageChange}
          />
        )}
        {type === 'number' && onChange && (
          <TextInput
            formName={formName}
            value={value as string}
            onChange={onChange}
          />
        )}
        {type === 'select' && onChange && selectOptions && (
          <SelectInput
            formName={formName}
            value={value as string}
            onChange={onChange}
            options={selectOptions}
          />
        )}
        {type === 'textarea' && onChange && (
          <TextAreaInput
            formName={formName}
            value={value as string}
            onChange={onChange}
            disabled={disabled}
          />
        )}
        {type === 'text' && onChange && (
          <TextInput
            disabled={disabled}
            formName={formName}
            value={value as string}
            maxLength={maxLength}
            onChange={onChange}
          />
        )}
        {!showError && description && <small className="form-text text-muted">{description}</small> }
        {showError && <small className="form-text text-danger">{errorText}</small> }
      </StyledInputContainer>
    </StyledInputGroup>
  )

}

export default InputGroup;