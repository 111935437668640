import { PartialRootState } from './configureStore';

import {
  initialBlogPostState,
  initialItemState,
  initialFTPState,
  initialOrderState,
  initialMapState,
  initialNotificationState,
  initialProfileState,
  initialPublicMatchState,
  initialServerState,
  initialStreamState,
  initialUserState,
  initialUsersState,
  initialVoucherState,
} from '@features'; 

const getPreloadedState = (): PartialRootState => {
  return {
    blogPost: initialBlogPostState,
    ftp: initialFTPState,
    item: initialItemState,
    map: initialMapState,
    notification: initialNotificationState,
    order: initialOrderState,
    profile: initialProfileState,
    publicMatch: initialPublicMatchState,
    server: initialServerState,
    stream: initialStreamState,
    user: initialUserState,
    users: initialUsersState,
    voucher: initialVoucherState,
  };
};

export default getPreloadedState;
