import React from 'react';

import { StyledTextInput } from './InputGroup.styled';

export interface ITextInput {
  /** is this input disabled */
  disabled?: boolean;
  maxLength?: number;

  formName: string;
  value: string;
  onChange: (newVal: string) => void;
}

const TextInput = ({
  disabled,
  maxLength,
  formName,
  onChange,
  value,
}: ITextInput) => {
  return (
    <StyledTextInput
      type="text"
      id={formName}
      name={formName}
      className="form-control"
      value={value}
      maxLength={maxLength}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    />
  );
}

export default TextInput;