import { api } from '@features';

import { IPublicMatch } from './PublicMatch';

export const publicMatchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMatchById: builder.query({
      query: (matchId: string) => {
        return {
          url: `/match/${matchId}`,
          method: 'GET',
        }
      },
      providesTags: ['Payment'],
      transformResponse: (response: { match: IPublicMatch, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.match;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getMatchList: builder.query({
      query: ({
        page = 1,
        limit = 10,
        gameMode = 'All'
      }) => {
        return {
          method: 'GET',
          url: `/match?page=${page}&limit=${limit}&gamemode=${gameMode}`,
        }
      },
      providesTags: ['Match'],
      transformResponse: (response: { matches: IPublicMatch[], success: boolean, totalPages: number, currentPage: number, limit: number, total: number }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    })
  }),
});

export const { useGetMatchByIdQuery, useGetMatchListQuery } = publicMatchApi;
