import styled from 'styled-components';


export const StyledInputGroup = styled.div`
  margin-top: 20px;
  display: flex;
  flex: 1;
  margin-bottom: 1rem;
`;

export const StyledInputLabel = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
`;