import React, { useContext, useMemo } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useOutlet } from 'react-router-dom';
import type { StoreDispatch, RootState } from './configureStore';
import { selectIsLoggedIn } from '@features';


// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => StoreDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

interface IAuth {
  isLoggedIn: boolean;
}

// @ts-ignore
const AuthContext = React.createContext<IAuth>(null);

interface IAuthProvider {
  children: React.ReactNode;
}

export function AuthProvider({ children }: IAuthProvider) {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const value = useMemo(
    () => ({
      isLoggedIn
    }),
    [isLoggedIn]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthLayout = () => {
  const outlet = useOutlet();

  return (
    <AuthProvider>{outlet}</AuthProvider>
  );
};