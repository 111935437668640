export enum EGameModes {
  Arena,
  CTF,
  Duel,
  Rabbit,
  TDM,
  League,
}

export const mapGameModes = [
  {
    label: 'Arena',
    value: 0
  },
  {
    label: 'CTF',
    value: 1
  },
  {
    label: 'Duel',
    value: 2
  },
  {
    label: 'Rabbit',
    value: 3
  },
  {
    label: 'TDM',
    value: 4
  },
  {
    label: 'League',
    value: 5
  },
];

export const mapTags = ['Desert', 'Grass', 'Snow', 'Building', 'Water', 'Ice'];

export interface IMap {
  _id: string;

  name: string;

  gameModes: EGameModes[];

  tags: string[];
  version: string;

  image: string;
  thumbnail: string;

  ladderActive: boolean;
  showOnMapsPage: boolean;
}