import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown, { DropdownProps  } from 'react-bootstrap/Dropdown';

export interface IDropdownItem {
  text: string;
  link?: string;
  onClick?: () => void;
  variant?: string;
}

export interface ISubmitButtonDropdown {
  onClick: () => void;
  isLoading?: boolean;
  dropDownItems: IDropdownItem[];
  text: string;
  styles?: React.CSSProperties;
  size?: string;
  variant?: string;
}

const SubmitButtonDropdown = ({
  onClick,
  isLoading = false,
  dropDownItems,
  text,
  styles,
  size,
  variant
}: ISubmitButtonDropdown) => {
  const ref = useRef<DropdownProps>();
  const [show, setShow] = useState(false);
  const buttonStyles = { marginTop: '15px', border: 'none', ...styles,};
  const butonSizeClass = `btn-${size}`;
  const buttonClass = !variant ? 'btn-purple' : `btn-${variant}`;

  const onItemClicked = (e: React.MouseEvent<HTMLButtonElement>, item: IDropdownItem) => {
    e.preventDefault();
    console.log('clicked', show)
    setShow(!show);
    if (item.onClick) {
      item.onClick();
    }
  }

  const onMainClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    console.log('clicked', show, ref.current?.show);
    e.preventDefault();
    setShow(!show);
    if (onClick) {
      onClick();
    }
  }

  return (
    // @ts-ignore
    <Dropdown as={ButtonGroup}>
      { /* @ts-ignore */ }
      <Button style={buttonStyles} className={`btn ${butonSizeClass} ${buttonClass}`} onClick={onMainClicked}>{isLoading ? <div className="spinner-border" role="status"></div> : text}</Button>
      <Dropdown.Toggle id="dropdown-split-basic"  className="btn-purple" style={buttonStyles}/>
      { /* @ts-ignore */ }
      <Dropdown.Menu show={show} ref={ref}>
        {dropDownItems.map((item, idx) => {
          if (item.link) {
            return <a className="dropdown-item" key={idx} href={item.link}>{item.text}</a>;
          }
          return  <button className={`btn ${(item.variant && item.variant.length) ? item.variant : 'btn-purple'}`} key={idx} onClick={(e) => onItemClicked(e, item)}>{item.text}</button>
        })}        
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SubmitButtonDropdown;