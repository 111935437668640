import { api } from '@features';

import { ICreateVoucher, IVoucher } from "./Voucher";

export const voucherApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createNewVoucher: builder.mutation<IVoucher[], ICreateVoucher>({
      query: (newVoucher: ICreateVoucher) => {
        return {
          method: 'POST',
          url: `/voucher`,
          body: newVoucher,
        }
      },
      invalidatesTags: ['Voucher'],
      transformResponse: (response: { vouchers: IVoucher[], success: boolean }, meta, arg) => {
        if (response.success) {
          return response.vouchers;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    getAllVouchers: builder.query({
      query: ({ page = 1, limit = 25, sort, search }) => {
        let url = `/voucher?page=${page}&limit=${limit}`;
        if (search) url += `&search=${search}`;
        if (sort && sort.length) url += `&sort=${sort}`;
        return {
          url,
          method: 'GET',
        }
      },
      providesTags: ['Voucher'],
      transformResponse: (response: { vouchers: IVoucher[], success: boolean; totalPages: number, currentPage: number, limit: number, total: number }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    redeemVoucher: builder.mutation<boolean, string>({
      query: (code: string) => {
        return {
          method: 'PUT',
          url: `/voucher/redeem`,
          body: { code }
        }
      },
      extraOptions: { maxRetries: 1 },
      invalidatesTags: ['Voucher'],
      transformResponse: (response: { success: boolean }, meta, arg) => {
        if (response.success) {
          return response.success;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
  }),
});

export const { useCreateNewVoucherMutation, useGetAllVouchersQuery, useRedeemVoucherMutation } = voucherApi;