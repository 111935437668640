import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { IMap } from './Map';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const mapAdapter = createEntityAdapter<IMap>({
  selectId: (map) => map._id,
});
export const initialMapState = mapAdapter.getInitialState();

const mapSlice = createSlice({
  name: 'map',
  initialState: initialMapState,
  reducers: {},
});

export default mapSlice.reducer;

export const {
  selectAll: selectAllMaps,
  selectById: selectMapById,
  selectIds: selectMapIds
} = mapAdapter.getSelectors((state: RootState) => state.map);