import { api } from './api';

export const gameliftApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGameliftInfo: builder.query({
      query: () => {
        return {
          method: 'GET',
          url: `/gamelift`,
        }
      },
      providesTags: ['Gamelift'],
      transformResponse: (response: { computeList: any; success: boolean }, meta, arg) => {
        if (response.success) {
          return response.computeList;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    registerNewCompute: builder.mutation({
      query: ({ name, ipAddress }) => {
        return {
          method: 'POST',
          url: `/gamelift/compute`,
          body: {
            name,
            ipAddress
          }
        }
      },
      invalidatesTags: ['Gamelift'],
      transformResponse: (response: { compute: any, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.compute;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
  }),
});

export const { useGetGameliftInfoQuery, useRegisterNewComputeMutation } = gameliftApi;