import React from 'react';
import { useTranslate } from "@tolgee/react";

import { StyledPaginateContainer, StyledPaginateButtonContainer, StyledPaginatePerPageContainer } from './CustomPaginate.styled';
import './CustomPaginate.styles.css';

interface ICustomPaginateProps {
  count: number,
  page: number,
  limit: number;
  rowsPerPage?: number
  onChangePage: (page: number, totalRows: number) => void;
  onChangeRowsPerPage: (currentRowsPerPage: number, currentPage: number) => void;
}

const NAVIGATION_BUTTON_STYLE: React.CSSProperties = {
  padding: '0.5rem 0.75rem',
  marginLeft: '-1px',
  lineHeight: '1.25',
  backgroundColor: '#101010',
  borderColor: '#0c0c0c',
  color: '#ccc',
  borderRadius: 0,
  zIndex: 3,
}

const NAVIGATION_ACTIVE_BUTTON_STYLE: React.CSSProperties = {
  padding: '0.5rem 0.75rem',
  marginLeft: '-1px',
  lineHeight: '1.25',
  backgroundColor: '#4E11BD',
  borderColor: '#4E11BD',
  color: '#ccc',
  borderRadius: 0,
  zIndex: 3
}

const CustomPaginate = ({ count, page, limit, onChangePage, onChangeRowsPerPage }: ICustomPaginateProps) => { 
  const { t } = useTranslate();
  const totalPages = Math.ceil(count / Number(limit));

  const changePerPage = (perPage: number) => {
    onChangeRowsPerPage(Number(perPage), page);
  }

  const handleBackButtonClick = () => {
    onChangePage(Number(page) - 1, count / limit);
  };

  const handleNextButtonClick = () => {
    onChangePage(Number(page) + 1, count / limit);
  }

  const handlePageClicked = (pageNumber: number) => {
      onChangePage(pageNumber + 1, count / limit);
  };


  let arr = Array.from(Array(Math.ceil(count / limit)).keys()).slice(Number(page) - 2, Number(page) + 3).length ?
    Array.from(Array(Math.ceil(count / limit)).keys()).slice(Number(page) - 2, Number(page) + 3) :
    Array.from(Array(Math.ceil(count / limit)).keys()).slice(Number(page) - 1, Number(page) + 4)

  if (arr.length === 1 && arr[0] === 1) {
    arr.unshift(0);
  }
  if (arr.length < totalPages && arr.length < 5) {
    const temp = [];
    for (let i = (arr[arr.length - 1] + 1) - 5; i < totalPages - 2; i++) {
      temp.push(i);
      if (arr.length + temp.length === 5) break;
    }
    arr = temp.concat(arr);
  }
  if (totalPages < 5) {
    const temp = [];
    for (let i = 0; i < totalPages; i++) {
      temp.push(i);
    }
    arr = temp;
  }
  
  return (
    <StyledPaginateContainer id="pagination-container">
      <div className="col-4" style={{ alignItems: 'center', display: 'flex' }}>
        { count ? (
          <span className="pagination-status white">
            {t('tables.showingResults', 'Showing {startResult} to {endResult} of {totalResults} Results', { startResult: (Math.ceil((page * limit) - limit) + 1).toString(), endResult: (page * limit) > count ? count.toString() : (page * limit).toString(), totalResults: count.toString() })}</span>
        ) : (
          <span className="pagination-status white">{t('tables.showingResults', { count })}</span>
        )}
      </div>
      <StyledPaginateButtonContainer>
        <ul className="pagination" id="pagination">
          <li className="paginate_button page-item previous" id="item-list_previous">
            <button className='btn' style={NAVIGATION_BUTTON_STYLE} disabled={page.toString() === '1'} onClick={handleBackButtonClick}>{t('tables.prevPage')}</button>
          </li>
          {arr.map((i) => {
            return (
              <li className={`paginate_button page-item`} key={i}>
                <button aria-controls="item-list" data-dt-idx={i + 1} className="btn" style={i + 1 === Number(page) ? NAVIGATION_ACTIVE_BUTTON_STYLE : NAVIGATION_BUTTON_STYLE} onClick={() => handlePageClicked(i)}>{i + 1}</button>
              </li>
            );
          })}
          <li className="paginate_button page-item next" id="item-list_next">
            <button className='btn' style={NAVIGATION_BUTTON_STYLE} disabled={page.toString() === totalPages.toString()} onClick={handleNextButtonClick}>{t('tables.nextPage')}</button>
          </li>
        </ul>
      </StyledPaginateButtonContainer>
      <StyledPaginatePerPageContainer>
        <div style={{ color: '#fff'}}>
          {t('tables.perPage')}
          <select className="form-check-label" id="perPage" value={limit} onChange={(e) => changePerPage(Number(e.target.value))} style={{ height: 'calc(1.5em + 0.75rem + 2px)', marginLeft: '5px' }}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>  
      </StyledPaginatePerPageContainer>
    </StyledPaginateContainer>
  );
}

export default CustomPaginate;