import React from 'react';

import { StyledTextArea } from './InputGroup.styled';

export interface ITextAreaInput {
  /** is this input disabled */
  disabled?: boolean;

  formName: string;
  value: string;
  onChange: (newVal: string) => void;
}

const TextAreaInput = ({
  disabled,
  formName,
  onChange,
  value,
}: ITextAreaInput) => {
  return (
    <StyledTextArea
      id={formName}
      name={formName}
      className="form-control"
      cols={50} rows={10}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    />
  );
}

<textarea id="comment" name="comment" className="form-control" cols={50} rows={10} style={{ height: 'auto' }}></textarea>

export default TextAreaInput;