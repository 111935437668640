import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { IBlogPost } from './BlogPost';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const blogPostAdapter = createEntityAdapter<IBlogPost>({
  selectId: (post) => post._id,
});
export const initialBlogPostState = blogPostAdapter.getInitialState();

const blogPostSlice = createSlice({
  name: 'blogPost',
  initialState: initialBlogPostState,
  reducers: {},
});

export default blogPostSlice.reducer;

export const {
  selectAll: selectAllBlogPosts,
  selectById: selectBlogPostById,
  selectIds: selectBlogPostIds
} = blogPostAdapter.getSelectors((state: RootState) => state.blogPost);