import React from 'react';

import { StyledTextInput } from './InputGroup.styled';

export interface IImageInput {
  formName: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ImageInput = ({
  formName,
  onChange,
}: IImageInput) => {
  return (
    <StyledTextInput
      type="file"
      id={formName}
      name={formName}
      className="form-control-filel"
      accept=".png, .jpg, .jpeg"
      onChange={onChange}
    />
  );
}

export default ImageInput;