import { api, IListMapResponse } from '@features';

import { IMap } from './Map';

export const mapApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createMap: builder.mutation<IMap, Partial<IMap>>({
      query: ({ name, gameModes, tags, image }) => {
        return {
          method: 'POST',
          url: `/map`,
          body: {
            name,
            gameModes,
            tags,
            image
          }
        }
      },
      invalidatesTags: [{ type: 'Map' }],
      transformResponse: (response: { map: IMap; success: boolean }, meta, arg) => {
        if (response.success) {
          return response.map;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response, meta, arg) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    deleteMap: builder.mutation<boolean, string>({
      query: (mapId) => {
        return {
          method: 'DELETE',
          url: `/map/${mapId}`,
        }
      },
      invalidatesTags: [{ type: 'Map' }],
      transformResponse: (response: { success: boolean }, meta, arg) => {
        if (response.success) {
          return response.success;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response, meta, arg) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    getMapById: builder.query<IMap, string>({
      query: (mapId) => {
        return {
          method: 'GET',
          url: `/map/${mapId}`
        };
      },
      providesTags: ['Map'],
      transformResponse: (response: { map: IMap, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.map;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }      
    }),
    getMapsList: builder.query<IListMapResponse, { page?: number, limit?: number, sort?: string, search?: string; hideImages?: boolean }>({
      query: ({ page = 1, limit = 25, sort, search, hideImages = false }) => {
        let url = `/map?page=${page}&limit=${limit}`;
        if (search) url += `&search=${search}`;
        if (sort && sort.length) url += `&sort=${sort}`;
        if (hideImages) url += `&hideImages=${hideImages}`;
        return {
          url,
          method: 'GET',
        };
      },
      providesTags: ['Map'],
      transformResponse: (response: IListMapResponse, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    updateMap: builder.mutation<IMap, Partial<IMap>>({
      query: (map) => {
        return {
          method: 'PUT',
          url: `/map/${map._id}`,
          body: map
        }
      },
      invalidatesTags: [{ type: 'Map' }],
      transformResponse: (response: { map: IMap; success: boolean }, meta, arg) => {
        if (response.success) {
          return response.map;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response, meta, arg) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
  }),
});

export const { useCreateMapMutation, useDeleteMapMutation, useGetMapByIdQuery, useGetMapsListQuery, useUpdateMapMutation } = mapApi;