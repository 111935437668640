import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { IProfile } from './Profile';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const profileAdapter = createEntityAdapter<IProfile>({
  selectId: (payment) => payment._id,
});
export const initialProfileState = profileAdapter.getInitialState();

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialProfileState,
  reducers: {},
});

export default profileSlice.reducer;

export const {
  selectAll: selectAllProfiles,
  selectById: selectProfileById,
  selectIds: selectProfileIds
} = profileAdapter.getSelectors((state: RootState) => state.profile);