import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { IUser } from './User';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>

const usersAdapter = createEntityAdapter<IUser>({
  selectId: (user) => user._id,
});
export const initialUsersState = usersAdapter.getInitialState();

const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsersState,
  reducers: {},
});

export default usersSlice.reducer;

export const {
  selectAll: selectAllUsers,
  selectById: selectUserId,
  selectIds: selectUserIds
} = usersAdapter.getSelectors((state: RootState) => state.users);