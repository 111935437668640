import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { INotification } from './Notification';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const notificationAdapter = createEntityAdapter<INotification>();
export const initialNotificationState = notificationAdapter.getInitialState();

const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialNotificationState,
  reducers: {},
});

export default notificationSlice.reducer;

export const {
  selectAll: selectAllNotifications,
  selectById: selectNotificationById,
  selectIds: selectNotificationIds
} = notificationAdapter.getSelectors((state: RootState) => state.notification);