import { api } from '@features';

import { IServer, IServerAudit } from "./Server";


export const serverApi = api.injectEndpoints({
  endpoints: (builder) => ({
    auditServers: builder.query<IServerAudit[], void>({
      query: () => {
        return {
          method: 'GET',
          url: `/server/audit`,
        }
      },
      extraOptions: { maxRetries: 0 },
      providesTags: ['Server'],
      transformResponse: (response: { audit: IServerAudit[], success: boolean }, meta, arg) => {
        if (response.success) {
          return response.audit;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    createServer: builder.mutation<IServer, Partial<IServer>>({
      query: (server: IServer) => {
        return {
          method: 'POST',
          url: `/server`,
          body: server,
        }
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ['Server'],
      transformResponse: (response: { server: IServer, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.server;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    deleteServer: builder.mutation({
      query: (serverId: string | number) => {
        return {
          method: 'DELETE',
          url: `/server/${serverId}`,
        };
      },
      invalidatesTags: ['Server'],
      transformResponse: (response: { success: boolean }, meta, arg) => {
        if (response.success) {
          return response.success;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    restartServer: builder.mutation({
      query: (serverId: string | number) => {
        return {
          method: 'PUT',
          url: `/server/restart/${serverId}`,
        };
      },
      invalidatesTags: ['Server'],
      transformResponse: (response: { server: IServer; success: boolean }, meta, arg) => {
        if (response.success) {
          return response.server;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    updateServer: builder.mutation({
      query: (server: IServer) => {
        return {
          method: 'PUT',
          url: `/server/${server._id}`,
          body: server
        };
      },
      invalidatesTags: ['Server'],
      transformResponse: (response: { server: IServer; success: boolean }, meta, arg) => {
        if (response.success) {
          return response.server;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    getPublicServers: builder.query({
      query: () => {
        return {
          url: 'server/public',
          method: 'GET',
        };
      },
      providesTags: ['Server'],
      transformResponse: (response: { servers: any, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.servers;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    getServerList: builder.query({
      query: ({ page = 1, limit = 25, search }) => {
        let url = `/server?page=${page}&limit=${limit}`;
        if (search) url += `&search=${search}`;
        return {
          url,
          method: 'GET',
        };
      },
      providesTags: ['Server'],
      transformResponse: (response: { servers: IServer[], success: boolean; totalPages: number, currentPage: number, limit: number, total: number }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    getdefaultServerSettings: builder.query({
      query: () => {
        let url = `/server/settings`;
        return {
          url,
          method: 'GET'
        };
      },
      providesTags: ['Server'],
      transformResponse: (response: { success: boolean; settings: any }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    updateDefaultServerSettings: builder.mutation({
      query: ({ admins, playtestVersion, version }: { admins: Array<string>; playtestVersion: string; version: string }) => {
        return {
          method: 'PUT',
          url: `/server/settings`,
          body: {
            admins,
            playtestVersion,
            version
          }
        };
      },
      invalidatesTags: ['Server'],
      transformResponse: (response: { settings: any; success: boolean }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
  }),
});

export const {
  useAuditServersQuery,
  useCreateServerMutation,
  useDeleteServerMutation,
  useGetServerListQuery,
  useRestartServerMutation,
  useUpdateServerMutation,
  useGetPublicServersQuery,
  useGetdefaultServerSettingsQuery,
  useUpdateDefaultServerSettingsMutation
} = serverApi;