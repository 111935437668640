import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

import type {
  IMap,
  IProfile
} from '@features';

const isProd = true; //process.env.NODE_ENV === 'production';

export interface IAPIError {
  message: string;
  success: boolean;
  validationErrors?: {[key: string]: string}[];
}

export interface IListResponse {
  success: boolean;
  totalPages: number,
  currentPage: number,
  limit: number,
  total: number
}

export interface IListMapResponse extends IListResponse {
  maps: IMap[];
}

export interface IListLadderProfileResponse extends IListResponse {
  profiles: IProfile[];
}

const baseQuery = fetchBaseQuery({
  baseUrl: isProd ? 'https://api.midair2.gg/v1' : 'http://localhost:9000/v1',
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = localStorage.getItem('jwtToken');
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const api = createApi({
  baseQuery: baseQueryWithRetry,
  tagTypes: ['BlogPost', 'Challenge', 'Dispute', 'FTP', 'Gamelift', 'Invitation', 'Item', 'Users', 'Ladder', 'LadderMatch', 'LadderMatchComment', 'Order', 'Map', 'Match', 'Notification', 'Payment', 'Profile', 'Server', 'Stream', 'Team', 'Voucher'],
  endpoints: () => ({}),
});