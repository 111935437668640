import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SubmitButton } from '@components';
import type { INotification } from '@features';

import {
  StyledNotificationContainer,
  StyledNotificationButtonContainer,
  StyledNotificationContentContainer,
  StyledNotificationTitleContainer
} from './NotificationContent.styled';

export interface ILadderMatchNotificationContent {
  notification: INotification
  deleteNotification: () => void;
}

const LadderMatchNotificationContent = ({
  notification,
  deleteNotification,
}: ILadderMatchNotificationContent) => {
  const navigate = useNavigate();

  return (
    <StyledNotificationContainer >
      <StyledNotificationTitleContainer>
        <h3>{notification.title}</h3>
      </StyledNotificationTitleContainer>
      <StyledNotificationContentContainer>
        <span>{notification.content}</span>
      </StyledNotificationContentContainer>
      <StyledNotificationButtonContainer>
        <SubmitButton onClick={() => navigate(`/ladders/manage/disputes`)} text='Manage' />
        <SubmitButton variant='danger' onClick={deleteNotification} text='Delete' />
      </StyledNotificationButtonContainer>
    </StyledNotificationContainer>
  );
}

export default LadderMatchNotificationContent;