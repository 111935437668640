import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

interface INavbarSection {
  /** Optional header component */
  header?: string;

  /** Dropdown button title */
  title?: string;

  /** Dropdown button link */
  link?: string;

  /** Function to call when clicking link */
  fn?: any;
  showNotification?: boolean;

  key?: number;
}

interface INavbarDropdownButton {

  /** Array of dropdown buttons */
  buttons: {
    sections: INavbarSection[];
  }[];

  /** The image to use for the button if appliciable */
  image?: string;

  /** The position of the image */
  imagePosition?: 'before' | 'after';

  /** Title of the dropdown */
  title: string;
  
  showNotification?: boolean;

  notificationCount?: number;
}

const SectionWithTitle = ({
  section,
}: { section: INavbarSection }) => {
  return (
    <>
      <Dropdown.Header as={CustomHeader}>{section.header}</Dropdown.Header>
      { !section.fn ? (
        // @ts-ignore
        <Dropdown.Item key={section.title} href={section.link}>{section.title}</Dropdown.Item >
      ) : (
        <Dropdown.Item key={section.title} onClick={section.fn}>{section.title}</Dropdown.Item >
      )}
    </>
  )
}

const CustomToggle = React.forwardRef(
  (props: any, ref: React.Ref<HTMLAnchorElement>) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className="nav-link dropdown-toggle"
      href="#"
      ref={ref}
      data-toggle="dropdown"
      aria-expanded="false"
      onClick={e => {
        e.preventDefault();
        props.onClick(e);
      }}
    >{props.children}</a>
  )
);

const CustomHeader = React.forwardRef(
  (props: any, ref: React.Ref<HTMLAnchorElement>) => (
    <span className="dropdown-item-text">{props.children}</span>
  )
);

const NavbarDropdownButton = ({
  buttons,
  image,
  imagePosition = 'after',
  title,
  showNotification = false,
  notificationCount = 1,
}: INavbarDropdownButton) => {
  return (
    <Dropdown className="nav-item">
      <Dropdown.Toggle id="dropdown01" className="nav-link" as={CustomToggle}>
        {showNotification && <span className="badge badge-danger"  style={{ maxHeight: '15px', marginRight: '5px' }}>{notificationCount}</span>}
        {image && imagePosition === 'before' && <img src={image} alt="credits-icon" height="25" width="25"  className="credits-icon"/>}
        {title}
        {image && imagePosition === 'after' && <img src={image} alt="credits-icon" height="25" width="25" className="credits-icon"/>}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {buttons.map((button, index) => {
          return (
            <div key={index}>
              {button.sections.map((section, bIdex) => {
                return section.header ? (
                  <div key={bIdex}>
                    { section.title === 'Notifications' && <span className="badge badge-danger">{notificationCount}</span>}
                    { section.title ? (
                      <SectionWithTitle section={section} />
                    ) : (
                      <Dropdown.Header as={CustomHeader}>{section.header}</Dropdown.Header>
                    )}
                  </div>
                ) : !section.fn ? (
                  section.title === 'Notifications' ? (
                    // @ts-ignore
                    <Dropdown.Item key={section.title} href={section.link}>
                      {showNotification && (
                        <span className="badge badge-danger" style={{ maxHeight: '15px', marginRight: '5px' }}>
                          {notificationCount}
                        </span>
                      )}
                      {section.title}
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item key={section.key || section.title} href={section.link}>{section.title}</Dropdown.Item >
                  )
                ) : (
                  <Dropdown.Item key={section.key || section.title}  onClick={section.fn}>{section.title}</Dropdown.Item >
                )
              })}
              { buttons.length - 1 !== index && <Dropdown.Divider /> }
            </div>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default NavbarDropdownButton;