import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { IVoucher } from './Voucher';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const voucherAdapter = createEntityAdapter<IVoucher>({
  selectId: (voucher) => voucher._id,
});
export const initialVoucherState = voucherAdapter.getInitialState();

const voucherSlice = createSlice({
  name: 'voucher',
  initialState: initialVoucherState,
  reducers: {},
});

export default voucherSlice.reducer;

export const {
  selectAll: selectAllVouchers,
  selectById: selectVoucherById,
  selectIds: selectVoucherIds
} = voucherAdapter.getSelectors((state: RootState) => state.voucher);