import React from 'react';

import { ENotificationStatus} from '@features';
import type { INotification } from '@features';
import AdminLogo from './admin.png';

export interface INotificationListItem {
  notification: INotification;
  notificationClicked: (notification: INotification) => void;
  isSelected: boolean;
}

const NotificationListItem = ({
  notification,
  notificationClicked,
  isSelected
}: INotificationListItem) => {
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', padding: '5px', cursor: 'pointer', backgroundColor: isSelected ? '#EC0868' : 'initial' }} onClick={() => notificationClicked(notification)}>
      <div style={{ display: 'flex', flex: 1 }}>
        {notification.status === ENotificationStatus.unread && <div style={{ height: '10px', width: '10px', borderRadius: '5px', backgroundColor: '#EC0868' }} />}
      </div>
      <div style={{ display: 'flex', flex: 1 }}>
        <img src={AdminLogo} alt="admin-logo" style={{ width: '30px' }} />
      </div>
      <div style={{ display: 'flex', flex: 5, flexDirection: 'column', alignItems: 'center', alignContent: 'center' }}>
        <span>Admin</span>
        <span>{notification.title}</span>
      </div>
    </div>
  );
}

export default NotificationListItem;