import { api } from '@features';

import { IOrder } from './Order';

interface OrdersReponse {
  orders: IOrder[],
  success: boolean;
  totalPages: number,
  currentPage: number,
  limit: number,
  total: number
}

export const orderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrder: builder.query<IOrder, string>({
      query: (orderId) => {
        return {
          method: 'GET',
          url: `/order/${orderId}`,
        };
      },
      providesTags: ['Order'],
      transformResponse: (response: { order: IOrder, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.order;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getOrders: builder.query<OrdersReponse, { page?: number; limit?: number; populate?: string, search?: string; sort?: string; }>({
      query: ({ limit = 1000, page = 1, populate, search, sort }) => {
        let url = `/order?page=${page}&limit=${limit}`;
        if (search) url += `&search=${search}`;
        if (sort && sort.length) url += `&sort=${sort}`;
        if (populate) url += `&populate=${populate}`;
        return {
          url,
          method: 'GET'
        };
      },
      providesTags: [{ type: 'Order' }],
      transformResponse: (response: OrdersReponse, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
  }),
});

export const {
  useGetOrderQuery,
  useGetOrdersQuery,
} = orderApi;