import React, { forwardRef, useState, useImperativeHandle } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import './AlertModal.css';

export type ValidationError = {
  [key: string]: string
};

export interface ValidationErrors {
  err: ValidationError;
};

export interface IAlertModal {
  onErrorClose?: () => void;
  onSuccessClose?: () => void;
}

export interface IAlertRef {
  showError: (text?: string) => void;
  showSuccess: (text?: string) => void;
}

const AlertModal = forwardRef(({
  onErrorClose,
  onSuccessClose
}: IAlertModal, ref) => {
  const [showing, setShowing] = useState(false);
  const [type, setType] = useState('success');
  const [displayText, setDisplayText] = useState('');

  useImperativeHandle(ref, () => ({
    showSuccess(text: string) {
      setType('success');
      setShowing(true);
      setDisplayText(text);
      window.scrollTo(0, 0);
    },
    showError(text: string) {
      setType('error');
      setShowing(true);
      setDisplayText(text);
      window.scrollTo(0, 0);   
    }
  }));

  const close = () => {
    if (onSuccessClose && type === 'success') {
      setShowing(false);
      onSuccessClose();
    } else if (!onSuccessClose && type === 'success') {
      setShowing(false);
    } else if (type === 'error' && !onErrorClose) {
      setShowing(false);
    }
  }

  return (
    <Modal
      show={showing}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className={`icon-box ${type === 'success' ? 'success' : 'error'}`}>
        <FontAwesomeIcon icon={type === 'success' ? faCheck : faTimes} size="4x" />
      </div>
      <div style={{ marginTop: '50px' }}>
        <div style={{ display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'column', padding: '50px', }}>
          <h4>{type === 'success' ? 'Success!' : 'Error'}</h4>
          <p>{displayText}</p>
          <button className="btn btn-purple" style={{ marginTop: '35px', width: '300px', height: '50px' }} onClick={close}>OK</button>
        </div>
      </div>
    </Modal>
  );
});

export default AlertModal;