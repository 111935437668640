import { api } from '@features';

import type { INotification } from './Notification';

export const notificationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteNotification: builder.mutation<boolean, number>({
      query: (notificationId: number) => {
        return {
          method: 'DELETE',
          url: `/notification/${notificationId}`,
        }
      },
      invalidatesTags: ['Notification'],
      transformResponse: (response: { success: boolean }, meta, arg) => {
        if (response.success) {
          return response.success;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    editNotification: builder.mutation({
      query: (notification: INotification) => {
        return {
          method: 'PUT',
          url: `/notification/${notification.id}`,
          body: notification
        }
      },
      invalidatesTags: ['Notification'],
      transformResponse: (response: { success: boolean }, meta, arg) => {
        if (response.success) {
          return response.success;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
  }),
});

export const { useDeleteNotificationMutation, useEditNotificationMutation } = notificationApi;