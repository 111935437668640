import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { IFTP } from './ftp';

const ftpAdapter = createEntityAdapter<IFTP>({});
export const initialFTPState = ftpAdapter.getInitialState();

const ftpSlice = createSlice({
  name: 'FTP',
  initialState: initialFTPState,
  reducers: {},
});

export default ftpSlice.reducer;