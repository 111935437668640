import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { IOrder } from './Order';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const orderAdapter = createEntityAdapter<IOrder>({
  selectId: (order) => order._id,
});
export const initialOrderState = orderAdapter.getInitialState();

const orderSlice = createSlice({
  name: 'order',
  initialState: initialOrderState,
  reducers: {},
});

export default orderSlice.reducer;

export const {
  selectAll: selectAllOrders,
  selectById: selectOrderById,
  selectIds: selectOrderIds
} = orderAdapter.getSelectors((state: RootState) => state.order);