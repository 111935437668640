import styled from 'styled-components';

export const StyledNotificationContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

export const StyledNotificationButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
`;

export const StyledNotificationContentContainer = styled.div`
  display: flex;
  flex: 11;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const StyledNotificationTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;