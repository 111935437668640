import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { IServer } from './Server';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const serverAdapter = createEntityAdapter<IServer>({
  selectId: (server) => server._id,
});
export const initialServerState = serverAdapter.getInitialState();

const serverSlice = createSlice({
  name: 'server',
  initialState: initialServerState,
  reducers: {},
});

export default serverSlice.reducer;

export const {
  selectAll: selectAllServers,
  selectById: selectServerById,
  selectIds: selectServerIds
} = serverAdapter.getSelectors((state: RootState) => state.server);