export interface IItem {
  _id: string;
  itemdefid?: number;
  type?: string;
  weapon_name?: string;
  display_type?: string;
  name?: string;
  bundle?: string;
  description?: string;
  background_color?: string;
  name_color?: string;
  price?: string;
  rarity?: string;
  asset_path?: string;
  is_default?: boolean;
  store_hidden?: boolean;
  icon_url?: string;
  icon_url_large?: string;
};

export const getWeaponName = (weaponName: string): string => {
  if (weaponName === 'grenade_launcher') {
    return 'Grenade Launcher';
  }
  return weaponName.charAt(0).toUpperCase() + weaponName.slice(1)
}

export const getItemName = (item: IItem): string => {
  switch (item.type) {
    case 'VoicePacks':
      return `${item.name} VoicePack`

    case 'Weapon Skin':
      return `${item.name} ${getWeaponName(item.weapon_name || '')} Skin`;

    case 'Spray':
      return `${item.name} Spray`;

    case 'Jet Trails':
      return `${item.name} Jet Trail`;

    case 'Armor Skin':
      return `${item.name} Armor skin`;

    default:
      return `${item.name}`;
  }
}