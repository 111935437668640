import { IItem } from '../item';
import { INotification } from '../notification';

export enum EUserRole {
  Developer,
  Admin,
  Moderator,
  Player,
  ServerAdmin
}

export const getUserRoleName = (role: number): string => {
  switch (role) {
    case EUserRole.Developer:
      return 'Developer';

    case EUserRole.Admin:
      return 'Admin';

    case  EUserRole.Moderator:
      return 'Moderator';

    case EUserRole.Player:
      return 'Player';

    case EUserRole.ServerAdmin:
      return 'Server Admin';

    default:
      return '';
  }
}

export interface IUser {
  _id: string;
  email: string;
  role: EUserRole; 
  verified: boolean;
  customerId: string;
  discordID: string;
  patreonID: string;
  steamID: string;
  displayName: string;
  avatar: string;
  profile: any;
  credits: number;
  //items: Array<IItem>;
  XP: number;
  XPToNextLevel: number;
  level: number;
  cdKey: string;
  isInClan: boolean;
  isBanned: boolean;
  bannedUntil: string;
  lastGameLogin: string;
  lastLogin: string;
  items: IItem[];
  createdAt: string;
  notifications: INotification[];
  patreonPledgeLevel: string;
};