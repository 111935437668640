import { IServer, api } from '@features';

import { IUser } from "./User";
import { IItem } from '../item';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: ({ page = 1, limit = 25, sort, search }) => {
        let url = `/user?page=${page}&limit=${limit}`;
        if (search) url += `&search=${search}`;
        if (sort && sort.length) url += `&sort=${sort}`;
        return {
          url,
          method: 'GET',
        }
      },
      providesTags: ['Users'],
      transformResponse: (response: { users: IUser[], success: boolean; totalPages: number, currentPage: number, limit: number, total: number }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getCurrentUser: builder.query<IUser, void>({
      query: () => {
        return {
          url: `/user/me`,
          method: 'GET',
        };
      },
      extraOptions: { maxRetries: 0 },
      providesTags: ['Users'],
      transformResponse: (response: { user: IUser, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.user;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        // @ts-ignore
        return response.data;
      }
    }),
    getCurrentUserItems: builder.query<IItem[], void>({
      query: () => {
        return {
          url: `/user/me/inventory`,
          method: 'GET',
        };
      },
      extraOptions: { maxRetries: 0 },
      providesTags: ['Users'],
      transformResponse: (response: { items: IItem[], success: boolean }, meta, arg) => {
        if (response.success) {
          return response.items;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        // @ts-ignore
        return response.data;
      }
    }),
    getUserBySteamID: builder.query({
      query: ({ id }) => {
        return {
          url: `/user/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['Users'],
      transformResponse: (response: { user: IUser, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.user;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        // @ts-ignore
        return response.data;
      }
    }),
    getUserServers: builder.query<IServer[], string>({
      query: (serverId) => {
        return {
          url: `/user/${serverId}/server`,
          method: 'GET',
        };
      },
      providesTags: ['Users'],
      transformResponse: (response: { servers: IServer[], success: boolean }, meta, arg) => {
        if (response.success) {
          return response.servers;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        // @ts-ignore
        return response.data;
      }
    }),
    userBuyItem: builder.mutation({
      query: (itemId: string) => {
        return {
          method: 'PUT',
          url: `/item/${itemId}/purchase`,
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwtToken')}`
          }
        }
      },
      invalidatesTags: ['Users'],
      transformResponse: (response: { success: boolean }, meta, arg) => {
        return response
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      },
    }),
    updateUserBySteamId: builder.mutation({
      query: ({
        userId,
        email,
        role,
        isBanned,
        credits
      }) => {
        return {
          method: 'PUT',
          url: `/user/${userId}`,
          body: {
            email,
            role,
            isBanned,
            credits            
          }
        }
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ['Users'],
      transformResponse: (response: { user: IUser, success: boolean }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    updateUserItemsBySteamId: builder.mutation({
      query: ({
        userId,
        itemsGiven,
        itemsTaken,
      }) => {
        return {
          method: 'PUT',
          url: `/user/${userId}/item`,
          body: {
            itemsGiven,
            itemsTaken,          
          }
        }
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ['Users'],
      transformResponse: (response: { user: IUser, success: boolean }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    updateUserDiscordId: builder.mutation({
      query: ({
        discordID,
      }) => {
        return {
          method: 'PUT',
          url: `/user/me/discord`,
          body: {
            discordID,          
          }
        }
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ['Users'],
      transformResponse: (response: { user: IUser, success: boolean }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    updateUserPatreonId: builder.mutation({
      query: ({
        patreonID,
      }) => {
        return {
          method: 'PUT',
          url: `/user/me/patreon`,
          body: {
            patreonID,          
          }
        }
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ['Users'],
      transformResponse: (response: { user: IUser, success: boolean }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    unlinkPatreon: builder.mutation({
      query: () => {
        return {
          url: `/auth/patreon`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Users'],
      transformResponse: (response: { success: boolean }, meta, arg) => {
        if (response.success) {
          return response.success;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        // @ts-ignore
        return response.data;
      }
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetCurrentUserQuery,
  useGetCurrentUserItemsQuery,
  useGetUserBySteamIDQuery,
  useGetUserServersQuery,
  useUserBuyItemMutation,
  useUpdateUserBySteamIdMutation,
  useUpdateUserItemsBySteamIdMutation,
  useUpdateUserDiscordIdMutation,
  useUpdateUserPatreonIdMutation,
  useUnlinkPatreonMutation
} = userApi;