import React from 'react';
import DataTableReact, { TableColumn } from 'react-data-table-component';
import { PaginationChangeRowsPerPage } from 'react-data-table-component/dist/src/DataTable/types';
import { useTranslate } from "@tolgee/react";

import type * as Types from '@features'
import CustomPaginate from '../CustomPaginate';

const defaultTableStyles = {
  backgroundColor: '#101010',
  color: 'white',
  fontSize: '0.8rem',
}

const NAVIGATION_BUTTON_STYLE= {
  padding: '0.5rem 0.75rem',
  marginLeft: '-1px',
  lineHeight: '1.25',
  backgroundColor: 'blue',
  borderColor: '#0c0c0c',
  color: '#ccc',
  borderRadius: 0,
  zIndex: 3,
}

const customStyles = {
  rows: {
    style: {
      ...defaultTableStyles,
    }
  },
	table: {
		style: {
      defaultTableStyles,
      padding: "20px !important",
      backgroundColor: "#101010",
      borderRadius: "10px"
    }
	},
  noData: {
    style: defaultTableStyles
  },
  pagination: {
    style: defaultTableStyles,
    pageButtonsStyle: NAVIGATION_BUTTON_STYLE
  },
  headCells: {
    style: {
      ...defaultTableStyles,
      fontWeight: 'bold'
    }
  },
  cells: {
    style: {
      ...defaultTableStyles,
      textOverflow: 'ellipsis !important',
      overflow: 'hidden'
    }
  },
};

export interface IPaginateOpts {
  currentPage: number;
  rowsPerPage: number;
  total: number;
  limit?: number;
  changePage: (page: number) => void;
  updateLimit?: (perPage: number) => void;  
}

interface IDataTable {
  columns: TableColumn<any>[];

  data:
    Types.IBlogPost[] |
    Types.IServer[] |
    Types.IServerAudit[] |
    Types.IItem[] |
    Types.IMap[] |
    Types.IProfile[] |
    Partial<Types.IProfile[]> |
    Types.IUser[] |
    Types.IVoucher[];

  paginateOpts?: IPaginateOpts;
  responsive?: boolean;
  pagination?: boolean;

  noDataComponent?: JSX.Element;
  sortServer?: boolean;
  defaultSortField?: string | null | number | undefined;
  defaultSortAsc?: boolean;
  handleSort?: (column: TableColumn<any>, sortDirection: string) => void;
}

const DataTable = ({
  pagination = true,
  columns,
  data,
  paginateOpts,
  responsive = true,
  noDataComponent,
  sortServer = false,
  defaultSortField,
  defaultSortAsc = true,
  handleSort
}: IDataTable) => {
  const { t } = useTranslate();
  const perPageChanged = (perPage: number, currentPage: number, fn: PaginationChangeRowsPerPage) => {
    if (paginateOpts && paginateOpts.updateLimit) {
      paginateOpts.updateLimit(perPage)
    }
    fn(perPage, currentPage);
  };

  return (
    <DataTableReact
      pagination={pagination}
      keyField={'_id'}
      customStyles={customStyles}
      columns={columns}
      data={[...data]}
      defaultSortFieldId={defaultSortField}
      defaultSortAsc={defaultSortAsc}
      responsive={responsive}
      onSort={handleSort}
      sortServer={sortServer}
      paginationServer={paginateOpts && paginateOpts.rowsPerPage ? true : false}
      noDataComponent={noDataComponent ? noDataComponent : t('tables.noResults')}
      paginationComponent={({ rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage, currentPage }) => (
        <CustomPaginate
          onChangeRowsPerPage={(perPage: number, currentPage: number) => perPageChanged(perPage, currentPage, onChangeRowsPerPage)}
          rowsPerPage={paginateOpts ? paginateOpts.rowsPerPage : 25}
          page={paginateOpts ? paginateOpts.currentPage : currentPage}
          limit={paginateOpts ? paginateOpts.rowsPerPage : rowsPerPage}
          count={paginateOpts ? paginateOpts.total : rowCount}
          onChangePage={paginateOpts ? paginateOpts.changePage : onChangePage}
        />
      )}
    />
  )
}

export default DataTable;