import React from  'react';
import DualListBox from 'react-dual-listbox';

import {
  StyledInputGroup,
  StyledInputLabel,
} from './DualListInputGroup.styled';

interface IListBoxOption {
  value: string;
  label: string;
}

export interface IDualListInputGroup {
  /** Description of the input */
  description?: string;

  /** The text to display if an error has occured */
  errorText?: string;

  /** The text to display as label */
  labelText: string;

  onChange: (newArray: string[]) => void;

  /** Dual list box options */
  options: IListBoxOption[];

  /** The name of the form */
  formName: string;

  /** Selected options */
  selected: string[];

  /** Should we show the error message */
  showError?: boolean;
}

const DualListInputGroup = ({
  description,
  errorText,
  labelText,
  options,
  onChange,
  formName,
  selected,
  showError,
}: IDualListInputGroup) => {
  return (
    <StyledInputGroup>
      <StyledInputLabel htmlFor={formName}>{labelText}</StyledInputLabel>
      <div className="col-9">
        <DualListBox
          canFilter 
          options={options}
          selected={selected}
          onChange={onChange}
        />
        {!showError && description && <small className="form-text text-muted">{description}</small> }
        {showError && <small className="form-text text-danger">{errorText}</small> }
      </div>
    </StyledInputGroup>
  );
}

export default DualListInputGroup;