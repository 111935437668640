import { Tolgee, DevTools, FormatSimple } from "@tolgee/react";
import { FormatIcu } from '@tolgee/format-icu';

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .use(FormatIcu())
  .init({
    language: 'en',

    // for development
    apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
    apiKey: process.env.REACT_APP_TOLGEE_API_KEY,

    // for production
    staticData: {
      en: () => import('./i18n/en.json'),
      de: () => import('./i18n/de.json'),
      es: () => import('./i18n/es.json'),
      fr: () => import('./i18n/fr.json'),
      ja: () => import('./i18n/ja.json'),
      ko: () => import('./i18n/ko.json')
    }
  });

export default tolgee;