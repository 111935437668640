import { api } from '@features';

import { IBlogPost } from './BlogPost';

export const blogPostApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createNewBlogPost: builder.mutation({
      query: (newPost: Partial<IBlogPost>) => {
        return {
          method: 'POST',
          url: `/blog`,
          body: newPost,
        }
      },
      invalidatesTags: ['BlogPost'],
      transformResponse: (response: { blogPost: IBlogPost, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.blogPost;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    deleteBlogPostById: builder.mutation<boolean, string>({
      query: (blogPostId) => {
        return {
          method: 'DELETE',
          url: `/blog/${blogPostId}`,
        }
      },
      invalidatesTags: ['BlogPost'],
      transformResponse: (response: { success: boolean }, meta, arg) => {
        if (response.success) {
          return response.success;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }      
    }),
    getBlogPost: builder.query<IBlogPost, string>({
      query: (blogPostId) => {
        return {
          method: 'GET',
          url: `/blog/${blogPostId}`,
        }
      },
      providesTags: ['BlogPost'],
      transformResponse: (response: { blogPost: IBlogPost, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.blogPost;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getBlogPostList: builder.query({
      query: ({ page = 1, limit = 25, sort, search }) => {
        let url = `/blog?page=${page}&limit=${limit}`;
        if (search) url += `&search=${search}`;
        if (sort && sort.length) url += `&sort=${sort}`;
        return {
          url,
          method: 'GET',
        }
      },
      providesTags: ['BlogPost'],
      transformResponse: (response: { blogPosts: IBlogPost[], success: boolean; totalPages: number, currentPage: number, limit: number, total: number }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    updateBlogPost: builder.mutation<IBlogPost, Partial<IBlogPost>>({
      query: (blogPost) => {
        return {
          method: 'PUT',
          url: `/blog/${blogPost._id}`,
          body: blogPost,
        }
      },
      invalidatesTags: ['BlogPost'],
      transformResponse: (response: { blogPost: IBlogPost, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.blogPost;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }      
    })
  }),
});

export const { useCreateNewBlogPostMutation, useDeleteBlogPostByIdMutation, useGetBlogPostQuery, useGetBlogPostListQuery, useUpdateBlogPostMutation } = blogPostApi;