import styled from 'styled-components';

export const StyledPaginateContainer = styled.div`
  margin-top: 25px;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledPaginateButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding-right: 20px;
  align-items: center;
  padding: 5px;
`;

export const StyledPaginatePerPageContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
`;