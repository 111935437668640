import { combineReducers } from 'redux';

import BlogPost from '@features/blogPosts/blogPostSlice';
import Items from '@features/item/itemSlice';
import FTP from '@features/ftp/ftpSlice';
import Order from '@features/order/orderSlice';
import Maps from '@features/map/mapSlice';
import Notification from '@features/notification/notificationSlice';
import Profiles from '@features/profile/profileSlice';
import PublicMatch from '@features/publicMatch/publicMatchSlice';
import Servers from '@features/server/serverSlice';
import Stream from '@features/stream/streamSlice';
import { api } from '@features';
import User from '@features/user/userSlice';
import Users from '@features/user/usersSlice';
import Vouchers from '@features/voucher/voucherSlice';

export default combineReducers({
  blogPost: BlogPost,
  item: Items,
  ftp: FTP,
  map: Maps,
  notification: Notification,
  order: Order,
  profile: Profiles,
  publicMatch: PublicMatch,
  server: Servers,
  stream: Stream,
  user: User,
  users: Users,
  voucher: Vouchers,
  [api.reducerPath]: api.reducer,
});
