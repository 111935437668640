import {
  createEntityAdapter,
} from '@reduxjs/toolkit'

import { api } from '@features';
import { IFTP } from './ftp';

export const ftpAdapter = createEntityAdapter();

export const ftpApi = api.injectEndpoints({
  endpoints: (builder) => ({
    runCommand: builder.mutation({
      query: ({ serverId, command, currentPath}: { serverId: string, command: string; currentPath: string }) => {
        return {
          method: 'POST',
          url: `/ftp/${serverId}`,
          body: {
            command,
            currentPath
          },
        }
      },
      invalidatesTags: ['FTP'],
      transformResponse: (response: { items: IFTP[]; success: boolean; }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    })
  }),
});

export const { useRunCommandMutation } = ftpApi;