import { api } from '@features';

import { IStream } from './Stream';

export const streamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStreams: builder.query<IStream, undefined>({
      query: () => {
        let url = `/twitch`;
        return {
          url,
          method: 'GET'
        };
      },
      providesTags: ['Stream'],
      transformResponse: (response: { success: true, streams: IStream }, meta, arg) => {
        if (response.success) {
          return response.streams;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
  }),
});

export const {
  useGetStreamsQuery,
} = streamApi;