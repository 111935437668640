import React, { useEffect } from 'react';

import ChallengeNotificationContent from './ChallengeNotificationContent';
import MatchDisputedNotificationContent from './MatchDisputedNotificationContent';

import {
  ENotificationStatus,
  ENotificationType,
  useDeleteNotificationMutation,
  useEditNotificationMutation
} from '@features';
import type { INotification } from '@features';

import {
  StyledNotificationContainer,
  StyledNotificationButtonContainer,
  StyledNotificationContentContainer,
  StyledNotificationTitleContainer
} from './NotificationContent.styled';
import SubmitButton from '@components/SubmitButton';

export interface INotificationContent {
  notification: INotification | null;
  getProfile: any;
  update: () => void;
}

const NotificationContent = ({
  getProfile,
  notification,
  update,
}: INotificationContent) => {
  const [editNotification] = useEditNotificationMutation();
  const [deleteNotification] = useDeleteNotificationMutation();

  const setNotificationBeenRead = async () => {
    if (notification) {
      const editedNotification = {...notification};
      editedNotification.status = ENotificationStatus.read;
      await editNotification(editedNotification);
      await getProfile();
    }
  }

  const notificationDelete = async () => {
    if (notification) {
      await deleteNotification(notification.id);
      update();
    }
  }

  useEffect(() => {
    if (notification?.status === ENotificationStatus.unread) {
      setNotificationBeenRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(notification && notification.id)]);

  const getContent = (): JSX.Element => {
    if (!notification) {
      return <></>;
    }
    if (notification.notificationType === ENotificationType.challengeSent) {
      return <ChallengeNotificationContent notification={notification} deleteNotification={notificationDelete} />;
    }
    if (notification.notificationType === ENotificationType.matchDispuated) {
      return <MatchDisputedNotificationContent notification={notification} deleteNotification={notificationDelete} />;
    }
    return (
      <StyledNotificationContainer key={notification.id}>
        <StyledNotificationTitleContainer>
          <h3>{notification.title}</h3>
        </StyledNotificationTitleContainer>
        <StyledNotificationContentContainer>
          <span>{notification.content}</span>
        </StyledNotificationContentContainer>
        <StyledNotificationButtonContainer>
          <SubmitButton variant="danger" onClick={notificationDelete} text='Delete' />
        </StyledNotificationButtonContainer>
      </StyledNotificationContainer>  
    );
  }

  return getContent();
}

export default NotificationContent;