export enum ENotificationType {
  challengeReceived,
  challengeSent,
  challengeAccepted,
  challengeDeclined,
  match,
  matchDispuated,
  inviteSent,
  inviteAccepted,
  inviteDeclined,
  serverConnected,
  serverInit,
  serverHostInit,
  serverInitialInstallComplete,
  serverMidairUpdated,
  serverInstallComplete,
  serverInstallError,
  purchase,
  redemption,
  itemBuy
}

export enum ENotificationStatus {
  'unread',
  'read',
  'deleted'
}

export interface INotification {
  id: number;

  /** The name of this ladder */
  title: string;

  content: string;

  notificationType: ENotificationType;

  status: ENotificationStatus;
  data: any;
  createdAt: string;
}