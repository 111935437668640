import {
  createSlice,
  createSelector,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { IItem } from './Item';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const itemsAdapter = createEntityAdapter<IItem>({
  selectId: (item) => {
    return item._id
  },
});
export const initialItemState = itemsAdapter.getInitialState();

const itemsSlice = createSlice({
  name: 'item',
  initialState: initialItemState,
  reducers: {},
});

export default itemsSlice.reducer;

export const {
  selectAll: selectAllItems,
  selectById: selectItemById,
  selectIds: selectItemIds
} = itemsAdapter.getSelectors((state: RootState) => state.item)

export const selectItemByDef = createSelector(
    [selectAllItems, (state, itemDef) => itemDef],
    (items, itemDef) => items.filter((item) => item.itemdefid === itemDef)
  );