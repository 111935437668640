import {
  createSlice,
  createSelector,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { EUserRole, IUser } from './User';
import { userApi } from './userApi';

const userAdapter = createEntityAdapter<IUser>({
  selectId: (user) => user._id,
});
export const initialUserState = userAdapter.getInitialState<{
  user: IUser | undefined;
  isLoggedIn: boolean;
  userToken: string | undefined;
}>({
  user: undefined,
  isLoggedIn: false,
  userToken: undefined,
});

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    logoutUser: (state) => {
      localStorage.clear();
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getCurrentUser.matchFulfilled, (state, action) => {
      state.user = action.payload;
      const token = localStorage.getItem('jwtToken') || undefined;
      state.userToken = token;
      if (action.payload) {
        state.isLoggedIn = true;
      } else {
        state.isLoggedIn = false;
      }
      return state;
    })
  }
});

export default userSlice.reducer;

export const { logoutUser } = userSlice.actions;

export const selectCurrentUser = userApi.endpoints.getCurrentUser.select();

export const selectIsLoggedIn = createSelector(selectCurrentUser, (state) => state.data && state.data._id ? true : false);

export const selectUserRole = createSelector(selectCurrentUser, (user) => {
  if (user.data && user.data.role in EUserRole) {
    return user.data.role;
  }
  return EUserRole.Player;
});